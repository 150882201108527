// import InsightsController from "@/controllers/insights_controller"
// import SortableHeaderController from './sortable_header_controller'
// // Configure Stimulus development experience
// // application.warnings = true
// // application.debug = true
// window.Stimulus = Application.start()

// Stimulus.register("insights", InsightsController)
// application.register("sortable-header", SortableHeaderController )


import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'
const controllers = import.meta.glob('./*_controller.js', { eager: true })
const application = Application.start()
registerControllers(application, controllers)

// Configure Stimulus development experience
application.warnings = true
application.debug = true
window.Stimulus = application
// export { application }

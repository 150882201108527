// import $ from 'jquery';
import Tablesort from 'tablesort'
import { showModal, hideModal } from './Modals'
import * as crypto from 'crypto'
/**
 * Random string generator that returns a string of alphanumeric contents
 *
 * @param {length} x length of string to return
 * @return {string} String
 */
export function randomString(length) {
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; i -= 1)
    result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

/**
 * Retrieve access token for authenticated actions and perform the given function with it
 *
 * @param {onSuccess} x function to be run on success
 * @param {onFail} x function to be run on failure
 */
let formatToISO8601 = function formatToYMD(date) {
  let y = date.getFullYear().toString()
  let m = (date.getMonth() + 1).toString()
  let d = date.getDate().toString()
  d.length === 1 && (d = '0' + d)
  m.length === 1 && (m = '0' + m)
  let yyyymmddString = y + m + d
  return yyyymmddString
}

//////////////////////////////
// Find closest parent node //
//////////////////////////////
/**
 * Returns a promise with the response if a valid 200 response is found
 *
 * @param {element} x element to search
 * @param {selector} x id or class you want to select
 * @return {element} element with
 */
export function getClosest(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        let matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length
        while ((i -= 1 >= 0 && matches.item(i) !== this)) {}
        return i > -1
      }
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem
  }
  return null
}

/**
 * Returns a promise with the response if a valid 200 response is found
 *
 * @param {element} elem element to get siblings of
 * @return {Array} Array of sibling elements
 */
export function getSiblings(elem) {
  // Setup siblings array and get the first sibling
  let siblings = []
  let sibling = elem.parentNode.firstChild

  // Loop through each sibling and push to the array
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling)
    }
    sibling = sibling.nextSibling
  }
  return siblings
}

//////////////////////////////////
// Dismiss modals on escape key //
//////////////////////////////////
const currentModal = document.querySelector('.show-modal')
currentModal &&
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      hideModal(currentModal)
    }
  })

/**
 * populates a dropdown with data from the portal API
 *
 * @param {element} elem element to get siblings of
 * @return {Array} Array of sibling elements
 * @warning Currently requires jQuery to run.
 */
export function populateDropdown(id, data, purge = false) {
  let dropdown = $(id)
  dropdown.selectedIndex = '0'
  if (purge) {
    dropdown.find('option').remove()
  }
  // dropdown.append($('<option></option>').attr('value', "").text("-- Please Select --").prop('disabled', "disabled").prop('selected', 'selected'));
  $.each(data, function (key, entry) {
    let dataAttributes = {}
    if (entry.data && Object.keys(entry.data).length > 0) {
      for (const [key, value] of Object.entries(entry.data)) {
        dataAttributes['data-' + key] = value
      }
    }
    let listItem = $('<option></option>')
      .attr('value', entry.value)
      .attr(dataAttributes)
      .text(entry.name)
      .prop('disabled', entry.disabled)
    dropdown.append(listItem)
  })
}
window.populateDropdown = populateDropdown

/**
 * Use Tablesort to sort a table
 * @param {*} element DOM selector
 * @param {*} order bool. default is ascending. true for descending
 */
export function sortTable(element, order = false) {
  if (element) {
    new Tablesort(element, { descending: order })
  }
}
window.sortTable = sortTable

/* eslint-disable no-param-reassign */
/**
 * Hides the modal as defined by the given element. Takes an optional callback function to process subsequent rest actions, such as clearing text boxes.
 *
 * @param {object} elem element to search
 * @param {function} resetCallback callback function to run after hiding the modal
 */
 export function hideModal(elem, resetCallback = () => {}) {
  elem.classList.remove('show-modal')
  document.removeEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      hideModal(elem)
    }
  })
  if (elem.querySelector('.field-validation-error'))
    elem.querySelector('.field-validation-error').textContent = ''

  const submitButton = elem.querySelector('.js-submit')
  if (submitButton) {
    submitButton.textContent = elem.dataset.submitLabel
    submitButton.disabled = false
    submitButton.classList.remove('button-disabled')
  }
  resetCallback()
}

/**
 * Displays a given modal element, and adds an escape key listener to close, and a click listener to the close button
 *
 * @param {element} x element to show
 */
 export function showModal(elem) {
  elem.classList.add('show-modal')
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      hideModal(elem)
    }
  })
  elem.querySelector('.close-button').addEventListener('click', () => {
    hideModal(elem)
  })
}

/**
 * Create a simple modal dialog and display it
 *
 * @param {string} id unique id of the dialog
 * @param {string} title title to be shown
 * @param {string} type dialogstyle notice, warning, danger
 * @param {string} content html content to be inserted in the modal
 */
export function createModal(id, title, type, content) {
  const modal = `<div class="modal" role="dialog" aria-labelledby="${id}" aria-describedby="modalDescription" id="${id}"><div class="modal-wrapper"><div class="modal-title modal-title-${type}">${title}<span class="close-button" aria-label="Close modal">&times;</span></div><div class="modal-content">${content}<p class="field-validation-error"></p></div></div></div>`
  const doc = new DOMParser().parseFromString(modal, 'text/html')
  document.getElementById('wrapper').appendChild(doc.children[0])
  showModal(document.getElementById(id))
}
window.createModal = createModal

import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
  connect() {
    const chartData = JSON.parse(document.getElementById('js-insights-chart-data').dataset.chartData)
    const theHelp = Chart.helpers
    Chart.defaults.plugins.legend.labels.boxWidth = 12
    const dailyUrls = document.getElementById('dailyUrlsChart')
    const dailyUrlsChart = new Chart(dailyUrls, {
      type: 'bar',
      animation: {
        animateScale: true
      },
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              footer: function (items) {
                return 'Total: ' + items.reduce((item, tootipEl) => item + tootipEl.parsed.y, 0)
              }
            }
          },
        },
        scales: {
          x: {
            stacked: true,
            gridLines: {
              display: false
            },
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            }
          },
          y: {
            ticks: {
              min: 0,
              stepSize: 5
            }
          }
        }
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const url = new URL(document.location);
    if (url.searchParams.get('sort_dir')) {
      document.querySelector(`[data-sort-by=${url.searchParams.get('sort_by')}`).dataset.sortDir = url.searchParams.get('sort_dir')
    }
  }

  resortTable(e) {
    let sortDir = 'asc'
    if (e.target.dataset.sortDir == 'asc') {
      e.target.dataset.sortDir = 'desc'
      sortDir = 'desc'
    } else {
      e.target.dataset.sortDir = 'asc'
    }
    const { sortBy } = e.target.dataset
    const url = new URL(document.location);
    url.searchParams.set('sort_by', sortBy);
    url.searchParams.set('sort_dir', sortDir);
    Turbo.visit(url)
  }
}

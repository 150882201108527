// import '../../stylesheets/app.scss'

import '@hotwired/turbo'
import Tablesort from 'tablesort'
import * as Alerts from '@/shared/Alerts'
import { copyClipboardText } from '@/_ui_tools.js'

import '../controllers/application'

function themeToggle() {
  const theme = localStorage.getItem('theme')
  if (theme && theme === 'light') {
    localStorage.setItem('theme', 'dark')
    document.documentElement.setAttribute('data-theme', 'dark')
  } else {
    localStorage.setItem('theme', 'light')
    document.documentElement.setAttribute('data-theme', 'light')
  }
}
window.addEventListener('turbo:load', () => {
  // const sortableTables = document.querySelectorAll(".table-sortable");
  // sortableTables.forEach((table) => {
  //   new Tablesort(table)
  // })

  // const deleteLinks = document.querySelectorAll('.button-delete')
  // deleteLinks.forEach((link) => {
  //   link.addEventListener('click', (event) => {
  //     event.preventDefault()
  //     let choice = confirm(this.getAttribute('data-confirm'));
  //     if (choice) {
  //       this.form.submit()
  //     }
  //   });
  // });

  const alerts = document.querySelectorAll('#flash-wrapper .alert')
  alerts.forEach((alert) => {
    setTimeout(() => {
      // alerts.classList.add('')
    })
  })

  const copyButtons = document.querySelectorAll('.clipboard-copy')
  copyButtons.forEach((link) => {
    link.addEventListener('click', (event) => {
      const text = event.target.dataset.fullUrl
      console.log(text)
      navigator.clipboard.writeText(text).then(() => {
        Alerts.setToast('Copied short URL to clipboard', 'notice')
      })
    })
  })

  const flashClose = document.querySelector('#flash .close')
  if (flashClose) {
    flashClose.addEventListener('click', function (e) {
      e.target.parentNode.classList.add('fadeout')
      setTimeout(() => {
        e.target.parentNode.parentNode.remove(e.target.parentNode)
      }, 1000)
    })
  }

  const logoutBtn = document.getElementById('js-user-logout')
  if (logoutBtn) {
    logoutBtn.addEventListener('click', (e) => {
      document.getElementById('user-logout').submit()
    })
  }

  const darkModeToggle = document.querySelector('input[name=dark-mode]')
  const currentTheme = localStorage.getItem('theme')
    ? localStorage.getItem('theme')
    : null
  if (currentTheme) {
    document.documentElement.setAttribute('data-theme', currentTheme)
    if (currentTheme === 'dark') {
      darkModeToggle.checked = true
    }
  }
  darkModeToggle?.addEventListener('change', themeToggle)
})

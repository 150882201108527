import { getClosest } from './Tools'
// Flash Alert javascripts
const FlashMessageType = {
    Notice: 0,
    Warning: 1,
    Danger: 2
}

Object.freeze(FlashMessageType)

/**
 * Sends a on screen toast notification
 *
 * @param {message} message the message you want to send to the UI
 * @param {type} type The type of message alert: Primary, Success, Notice, Warning or Danger. Determines toast appearance
 * @param {title} title Dialog title to be shown
 * @param {dismiss} dismiss Automatically dismiss after a short time. Set to false to remain in screen
 */
/*
<div id="flash-container">
  <div class="alert alert-notice" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="title-block">
    <div class="title">Title</div><a class="close" data-dismiss="alert" aria-label="close">×</a></div>
    <div id="flash-notice"><p>Deleted!</p></div>
  </div>
</div>
*/
export function setToast(message, type, title = "", dismiss = true) {
    let flashAlert = document.createElement("div")
    flashAlert.id = 'toast'
    flashAlert.classList.add('alert')
    flashAlert.classList.add('alert-' + type)
    flashAlert.setAttribute("role", "alert")
    flashAlert.setAttribute('aria-live', 'assertive')
    flashAlert.setAttribute('aria-atomic', 'true')

    let titleblock = document.createElement('div')
    titleblock.classList.add('title-block')
    let titleEl = document.createElement('div')
    titleEl.classList.add('title')
    if (title !== "")
    {
        titleEl.textContent = title
    }

    let closeButton = document.createElement("a")
    closeButton.classList.add('close')
    closeButton.setAttribute("data-dismiss", "alert")
    closeButton.setAttribute('aria-label', 'close')
    closeButton.innerHTML = '&times;'

    let flashMessage = document.createElement('div')
    flashMessage.innerHTML = '<p>' + message + '</p>'
    flashMessage.id = 'flash-notice'

    titleblock.appendChild(titleEl)
    titleblock.appendChild(closeButton)
    flashAlert.appendChild(titleblock)
    flashAlert.appendChild(flashMessage)
    let container = document.getElementById('flash-container')
    container.insertBefore(flashAlert, container.firstChild)
    closeButton.addEventListener('click', dismissFlash, false)

    if (dismiss)
    {
        setTimeout(() => {
          dismissFlash(flashAlert)
        }, 8000);
    }
}

function dismissFlash(event){
    event.target.classList.add("fadeout")
    const flash = event.target.closest('#toast')
    document.querySelector('#flash-container').classList.add('fadeout')
    setTimeout(() => {
      document.querySelector('#flash-container').remove(flash)
    }, 1000);
}

